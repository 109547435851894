import {
    PURCHASE_PACKAGE_REQUEST,
    PURCHASE_PACKAGE_SUCCESS,
    PURCHASE_PACKAGE_FAILURE,
  } from "../actions/types";
  
  const initialState = {
    loading: false,
    error: null,
  };
  
  const packagesReducer = (state = initialState, action) => {
    switch (action.type) {
      case PURCHASE_PACKAGE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case PURCHASE_PACKAGE_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
        };
      case PURCHASE_PACKAGE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default packagesReducer;
  