import {  
  FETCH_TEACHER_PRICE_REQUEST,
  FETCH_TEACHER_PRICE_SUCCESS,
  FETCH_TEACHER_PRICE_FAILURE, 
} from "../actions/types";


const initialState = {
  teacherPrice: null,
  loading: false,
  error: null,
};

 const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEACHER_PRICE_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_TEACHER_PRICE_SUCCESS:
      return { ...state, loading: false, teacherPrice: action.payload };
    case FETCH_TEACHER_PRICE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    // ... other action types ...
    default:
      return state;
  }
}

export default profileReducer;