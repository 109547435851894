
import {
  STUDENT_USER_LOADED,
  STUDENT_USER_FAILED,
  TEACHER_USER_LOADED,
  TEACHER_USER_FAILED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_SUCCESS,
  REGISTER_SUSER_SUCCESS,
  REGISTER_TUSER_FAILED,
  REGISTER_TUSER_SUCCESS,
  REGISTER_SUSER_FAILED,
  CHANGE_PASSWORD,
  CURRENT_PASSWORD_FIELD,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_FAIL,
  EMAIL_CHANGE_SUCCESS,
  EMAIL_CHANGE_FAIL,
  RESET_EMAIL_UPDATE_SUCCESS,
  EMAIL_CHANGE_CONFIRM_SUCCESS,
  EMAIL_CHANGE_CONFIRM_FAIL,
  ACCOUNT_UPDATED_SUCCESS,
  ACCOUNT_UPDATED_FAIL,
  REDIRECT_TO_PAYMENT,
  FACEBOOK_LOGIN_SUCCESS,
  FACEBOOK_LOGIN_FAILED,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_FAILED,
  FETCH_BALANCES_SUCCESS,
  FETCH_BALANCES_FAILED,
  POST_TEACHER_APPLICATION_REQUEST,
  POST_TEACHER_APPLICATION_SUCCESS,
  POST_TEACHER_APPLICATION_FAILURE,
  RESET_UPDATE_SUCCESS,
  CLEAR_ERRORS,
  UPDATE_TEACHER_BALANCE,
  GOOGLE_AUTH_SUCCESS, 
  GOOGLE_AUTH_FAIL,
  SUPPORT_SIGNUP_REQUEST,
  SUPPORT_SIGNUP_SUCCESS,
  SUPPORT_SIGNUP_FAILURE,
  SUPPORT_DASHBOARD_REQUEST,
  SUPPORT_DASHBOARD_SUCCESS,
  SUPPORT_DASHBOARD_FAILURE
} from "../actions/types";
import storage from "../../components/storage";

const initialState = {
  isAuthenticated: false,
  role: null,  // Use role instead of isStudent
  isLoading: false,
  user: null,
  selectedSlot: null,
  teachers: [],
  updateSuccess: false,
  emailUpdateSuccess: false,
  error: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_SUSER_SUCCESS:
      storage.setItem("token", action.payload.token);
      return {
        ...initialState, // Reset to initialState
        ...action.payload, // Apply the payload to the reset state
        isAuthenticated: true,
        role: 'Student',  // Set role to 'Student'
        isLoading: false,
        selectedSlot: action.payload.setSelectedSlot,
      };

    case REGISTER_TUSER_SUCCESS:
      storage.setItem("token", action.payload.token);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        role: 'Teacher',  // Set role to 'Teacher'
        isLoading: false,
        user: action.payload.user, // assuming the user data is in action.payload.user
        error: null, // Clear any previous errors on successful registration
      };

    case STUDENT_USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        role: 'Student',  // Set role to 'Student'
        user: action.payload,
      };

    case TEACHER_USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        role: 'Teacher',  // Set role to 'Teacher'
        user: action.payload,
      };

      case LOGIN_SUCCESS:
        storage.setItem("token", action.payload.token);
        return {
          ...initialState, // Reset to initialState
          ...action.payload, // Apply the payload to the reset state
          isAuthenticated: true,
          isLoading: false,
          role: action.payload.role,  // Correctly access role directly from the payload
        };
      

    case REGISTER_SUSER_FAILED:
    case REGISTER_TUSER_FAILED:
    case LOGIN_FAILED:
      storage.removeItem("token");
      return {
        ...state,
        token: null,
        role: null,  // Reset role on failure
        isAuthenticated: false,
        isLoading: false,
        error: action.payload, // Capture the error message
      };

    case STUDENT_USER_FAILED:
    case TEACHER_USER_FAILED:
    case LOGOUT_SUCCESS:
      storage.removeItem("token");
      return {
        ...initialState, // Reset to initialState to clear all user data
      };

    case PASSWORD_RESET_SUCCESS:
    case PASSWORD_RESET_FAIL:
    case PASSWORD_RESET_CONFIRM_SUCCESS:
    case PASSWORD_RESET_CONFIRM_FAIL:
      return {
        ...state,
      };
    case PASSWORD_CHANGE_SUCCESS:
    case PASSWORD_CHANGE_FAIL:
      return {
        ...state,
      };
      case EMAIL_CHANGE_SUCCESS:
        return {
          ...state,
          user: {
            ...state.user,
            email: action.payload.email, // Update the email in your user object
          },
          emailUpdateSuccess: true, // Indicate that the email update was successful
        };
      case EMAIL_CHANGE_FAIL:
        return {
          ...state,
          emailUpdateSuccess: false, // Ensure the flag is reset on fail
        };
      case RESET_EMAIL_UPDATE_SUCCESS:
        return {
          ...state,
          emailUpdateSuccess: false, // Reset the success flag
        };
      case ACCOUNT_UPDATED_SUCCESS:
        // Assuming action.payload contains the updated user object
        storage.setItem("token", action.payload.token); // If the token is also updated
        return {
          ...state,
          user: action.payload.user, // Update the user details in the state
          isAuthenticated: true, // You might want to ensure authentication stays true
          updateSuccess: true, // Set this to true when the account update is successful
          // Include any other state updates that are appropriate upon successful account update
        };
      case ACCOUNT_UPDATED_FAIL:
        // Handle the account update failure case
        // You might want to set an error message in the state to display to the user
        return {
          ...state,
          error: action.payload.error, // action.payload should contain the error information
        };
        case RESET_UPDATE_SUCCESS:
  return {
    ...state,
    updateSuccess: false, // Reset the update success state
  };
      case REDIRECT_TO_PAYMENT:
        return {
          ...state,
          redirectToPayment: action.payload,
      };
      case FACEBOOK_LOGIN_SUCCESS:
  storage.setItem("token", action.payload.token);
  return {
    ...state,
    ...action.payload,
    isAuthenticated: true,
    isStudent: true, // Assuming the user is a student when authenticating with Facebook
    isLoading: false,
  };
case FACEBOOK_LOGIN_FAILED:
  storage.removeItem("token");
  return {
    ...state,
    token: null,
    isAuthenticated: false,
    isStudent: false,
    isLoading: false,
  };
  case GOOGLE_LOGIN_SUCCESS:
    storage.setItem("token", action.payload.token);
    return {
      ...initialState, // Reset to initialState
      ...action.payload, // Apply the payload to the reset state
      isAuthenticated: true,
      role: 'Student',  // Use role from the backend
      isLoading: false,
      selectedSlot: action.payload.selectedSlot,
    };
    case FETCH_BALANCES_SUCCESS:
      return {
        ...state,
        teachers: action.payload,
      };
    case FETCH_BALANCES_FAILED:
      return {
        ...state,
        balance: null,
      };
      case UPDATE_TEACHER_BALANCE:
        return {
          ...state,
          teachers: state.teachers.map((teacher) =>
            teacher.id === action.payload.teacherId
              ? { ...teacher, balance: parseFloat(teacher.balance) + action.payload.additionalHours }
              : teacher
          ),
        };
      case CLEAR_ERRORS:
  return {
    ...state,
    error: null, // or {} depending on how you want to handle the error state
  };
  case GOOGLE_LOGIN_SUCCESS:
    storage.setItem("token", action.payload.token);
    return {
      ...initialState, // Reset to initialState
      ...action.payload, // Apply the payload to the reset state
      isAuthenticated: true,
      role: 'Student',  // Use role from the backend
      isLoading: false,
      selectedSlot: action.payload.selectedSlot,
    };
  
  case GOOGLE_AUTH_FAIL:
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      role: null,  // Reset role on failure
      isLoading: false,
      error: action.payload, // The error message from the action
    };
        // New Actions
        case SUPPORT_SIGNUP_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case SUPPORT_SIGNUP_SUCCESS:
            storage.setItem("token", action.payload.token);
            return {
                ...initialState,
                ...action.payload,
                isAuthenticated: true,
                role: 'Support',  // Set role to 'Support'
                isLoading: false,
            };

        case SUPPORT_SIGNUP_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case SUPPORT_DASHBOARD_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case SUPPORT_DASHBOARD_SUCCESS:
            return {
                ...state,
                user: action.payload,  // Set user data
                isLoading: false,
            };

        case SUPPORT_DASHBOARD_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

  }
  return state;
};

