import { SET_AVAILABILITIES, AVAILABILITY_REQUEST, SET_AVAILABILITY_ERROR } from '../actions/types';

const initialState = {
  availabilities: [],
  loading: false,
  error: null,
};

const availabilitiyReducer = (state = initialState, action) => {
  switch (action.type) {
    case AVAILABILITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SET_AVAILABILITIES:
      return {
        ...state,
        availabilities: action.payload,
        loading: false,
        error: null,
      };
    case SET_AVAILABILITY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};


export default availabilitiyReducer;
