export const STUDENT_USER_LOADED = "STUDENT_USER_LOADED";
export const STUDENT_USER_FAILED = "STUDENT_USER_FAILED";
export const TEACHER_USER_LOADED = "TEACHER_USER_LOADED";
export const TEACHER_USER_FAILED = "TEACHER_USER_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_TUSER_SUCCESS = "REGISTER_TUSER_SUCCESS";
export const REGISTER_TUSER_FAILED = "REGISTER_TUSER_FAILED";
export const REGISTER_SUSER_SUCCESS = "REGISTER_SUSER_SUCCESS";
export const REGISTER_SUSER_FAILED = "REGISTER_SUSER_FAILED";
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_CONFIRM_FAIL = "PASSWORD_RESET_CONFIRM_FAIL";
export const PASSWORD_RESET_CONFIRM_SUCCESS = "PASSWORD_RESET_CONFIRM_SUCCESS";
export const PASSWORD_CHANGE_SUCCESS = "PASSWORD_CHANGE_SUCCESS";
export const PASSWORD_CHANGE_FAIL = "PASSWORD_CHANGE_FAIL";
export const EMAIL_CHANGE_SUCCESS = "EMAIL_CHANGE_SUCCESS";
export const EMAIL_CHANGE_FAIL = "EMAIL_CHANGE_FAIL";
export const RESET_EMAIL_UPDATE_SUCCESS = "RESET_EMAIL_UPDATE_SUCCESS";
export const EMAIL_CHANGE_CONFIRM_SUCCESS = "EMAIL_CHANGE_CONFIRM_SUCCESS";
export const EMAIL_CHANGE_CONFIRM_FAIL = "EMAIL_CHANGE_CONFIRM_FAIL";
export const ACCOUNT_UPDATED_SUCCESS = "ACCOUNT_UPDATED_SUCCESS";
export const ACCOUNT_UPDATED_FAIL = "ACCOUNT_UPDATED_FAIL";
export const TIMEZONE_UPDATED_SUCCESS = "PHOTO_UPDATED_SUCCESS";
export const TIMEZONE_UPDATED_FAIL = "PHOTO_UPDATED_FAIL";
export const TUTOR_PROFILE_LOADED = "TUTOR_PROFILE_LOADED";
export const TUTOR_PROFILE_FAILED = "TUTOR_PROFILE_FAILED";

export const GET_CONVERSATION = "GET_CONVERSATION";
export const GET_CONVERSATION_SUCCESS = "GET_CONVERSATION_SUCCESS";
export const GET_CONVERSATION_FAIL = "GET_CONVERSATION_FAIL";

export const SENDING_MESSAGE = "SENDING_MESSAGE";
export const MESSAGE_SENT = "MESSAGE_SENT";
export const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR";

export const CREATE_CONVERSATION = "CREATE_CONVERSATION";
export const CREATE_CONVERSATION_SUCCESS = "CREATE_CONVERSATION_SUCCESS";
export const CREATE_CONVERSATION_FAIL = "CREATE_CONVERSATION_FAIL";

export const FETCH_CONVERSATIONS = "FETCH_CONVERSATIONS";
export const FETCH_CONVERSATIONS_ERROR = "FETCH_CONVERSATIONS_ERROR";

export const FETCH_TEACHER = "FEATCH_TEACHER";

export const FETCH_MESSAGES_START = "FETCH_MESSAGES_START";
export const FETCH_MESSAGES_SUCCESS = "FETCH_MESSAGES_SUCCESS";
export const FETCH_MESSAGES_FAIL = "FETCH_MESSAGES_FAIL";

// chat TYpes//
//
//
//
export const CHAT_GROUPS = "CHAT_GROUPS";
export const NEW_CHAT_GROUP = "NEW_CHAT_GROUP";
export const MESSAGES = "MESSAGES";
export const NEW_MESSAGE = "NEW_MESSAGE";
export const SELECTED_CHAT_GROUP = "SELECTED_CHAT_GROUP";
export const UNREAD_CHATS = "UNREAD_CHATS";
export const NEW_SINGLE_MESSAGE = "NEW_SINGLE_MESSAGE";
export const UNREAD_CHATS_COUNT = 'UNREAD_CHATS_COUNT';
export const SET_UNREAD_COUNT = 'SET_UNREAD_COUNT';
export const ADD_PREVIOUS_MESSAGES = 'ADD_PREVIOUS_MESSAGES';

// calendar //

export const UPDATE_AVAILABILITIES_SUCCESS = "UPDATE_AVAILABILITIES_SUCCESS";
export const UPDATE_AVAILABILITIES_FAILURE = "UPDATE_AVAILABILITIES_FAILURE";
export const RESET_UPDATE_SUCCESS = 'RESET_UPDATE_SUCCESS';

export const FETCH_TEACHER_AVAILABILITIES = "FETCH_TEACHER_AVAILABILITIES";
export const SET_AVAILABILITY_LOADING = 'SET_AVAILABILITY_LOADING';
export const SET_AVAILABILITY = 'SET_AVAILABILITY';
export const SET_LOADING = 'SET_LOADING';
export const AVAILABILITY_REQUEST = 'AVAILABILITY_REQUEST';
export const SET_AVAILABILITIES = 'SET_AVAILABILITIES';
export const SET_AVAILABILITY_ERROR = 'SET_AVAILABILITY_ERROR';
export const FETCH_TEACHER_AVAILABILITY_REQUEST = 'FETCH_TEACHER_AVAILABILITY_REQUEST'; // Added new action type

// book lessons //
export const BOOK_LESSON_REQUEST = 'BOOK_LESSON_REQUEST';
export const BOOK_LESSON_SUCCESS = "BOOK_LESSON_SUCCESS";
export const BOOK_LESSON_FAILED = "BOOK_LESSON_FAILED";


export const REDIRECT_TO_PAYMENT = "REDIRECT_TO_PAYMENT";
export const SET_HREF = "SET_HREF";

// Add these action types to your types.js file
export const FACEBOOK_LOGIN_SUCCESS = "FACEBOOK_LOGIN_SUCCESS";
export const FACEBOOK_LOGIN_FAILED = "FACEBOOK_LOGIN_FAILED";

export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const GOOGLE_LOGIN_FAILED = "GOOGLE_LOGIN_FAILED";

export const FETCH_BALANCES_SUCCESS = "FETCH_BALANCES_SUCCESS";
export const FETCH_BALANCES_FAILED = "FETCH_BALANCES_FAILED";

export const UPDATE_BALANCE = 'UPDATE_BALANCE';

export const PURCHASE_PACKAGE_REQUEST = "PURCHASE_PACKAGE_REQUEST";
export const PURCHASE_PACKAGE_SUCCESS = "PURCHASE_PACKAGE_SUCCESS";
export const PURCHASE_PACKAGE_FAILURE = "PURCHASE_PACKAGE_FAILURE";


export const FETCH_TEACHER_PRICE_REQUEST = "FETCH_TEACHER_PRICE_REQUEST";
export const FETCH_TEACHER_PRICE_SUCCESS = "FETCH_TEACHER_PRICE_SUCCESS";
export const FETCH_TEACHER_PRICE_FAILURE = "FETCH_TEACHER_PRICE_FAILURE";

export const SCHEDULE_LESSON_REQUEST = 'SCHEDULE_LESSON_REQUEST';
export const SCHEDULE_LESSON_SUCCESS = 'SCHEDULE_LESSON_SUCCESS';
export const SCHEDULE_LESSON_FAILURE = 'SCHEDULE_LESSON_FAILURE';

export const FETCH_LESSONS = "FETCH_LESSONS";

export const SET_SESSION_INFO_START = 'SET_SESSION_INFO_START';
export const SET_SESSION_INFO_SUCCESS = 'SET_SESSION_INFO_SUCCESS';
export const SET_SESSION_INFO_FAIL = 'SET_SESSION_INFO_FAIL';

export const GET_SESSION_INFO_START = 'GET_SESSION_INFO_START';
export const GET_SESSION_INFO_SUCCESS = 'GET_SESSION_INFO_SUCCESS';
export const GET_SESSION_INFO_FAIL = 'GET_SESSION_INFO_FAIL';


//Reviews
export const FETCH_REVIEWS_BEGIN = 'FETCH_REVIEWS_BEGIN';
export const FETCH_REVIEWS_SUCCESS = 'FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_FAILURE = 'FETCH_REVIEWS_FAILURE';

export const CREATE_REVIEW_BEGIN = 'CREATE_REVIEW_BEGIN';
export const CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS';
export const CREATE_REVIEW_FAILURE = 'CREATE_REVIEW_FAILURE';

export const UPDATE_REVIEW_BEGIN = 'UPDATE_REVIEW_BEGIN';
export const UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEW_SUCCESS';
export const UPDATE_REVIEW_FAILURE = 'UPDATE_REVIEW_FAILURE';

export const DELETE_REVIEW_BEGIN = 'DELETE_REVIEW_BEGIN';
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS';
export const DELETE_REVIEW_FAILURE = 'DELETE_REVIEW_FAILURE';


//Teacher application 

export const POST_TEACHER_APPLICATION_REQUEST = "POST_TEACHER_APPLICATION_REQUEST";
export const POST_TEACHER_APPLICATION_SUCCESS = "POST_TEACHER_APPLICATION_SUCCESS";
export const POST_TEACHER_APPLICATION_FAILURE = "POST_TEACHER_APPLICATION_FAILURE";


export const CLEAR_ERRORS = 'CLEAR_ERRORS';


// teacherApplicationActionTypes.js

//Photo
//Update
export const UPLOAD_TEACHER_PHOTO_START = 'UPLOAD_TEACHER_PHOTO_START';
export const UPLOAD_TEACHER_PHOTO_SUCCESS = 'UPLOAD_TEACHER_PHOTO_SUCCESS';
export const UPLOAD_TEACHER_PHOTO_FAILURE = 'UPLOAD_TEACHER_PHOTO_FAILURE';
//Get
export const FETCH_TEACHER_PHOTO_START = 'FETCH_TEACHER_PHOTO_START';
export const FETCH_TEACHER_PHOTO_SUCCESS = 'FETCH_TEACHER_PHOTO_SUCCESS';
export const FETCH_TEACHER_PHOTO_FAILURE = 'FETCH_TEACHER_PHOTO_FAILURE';

//VIDEO , GET AND UPDATE
export const FETCH_TEACHER_VIDEO_START = 'FETCH_TEACHER_VIDEO_START';
export const FETCH_TEACHER_VIDEO_SUCCESS = 'FETCH_TEACHER_VIDEO_SUCCESS';
export const FETCH_TEACHER_VIDEO_FAILURE = 'FETCH_TEACHER_VIDEO_FAILURE';
export const UPDATE_TEACHER_VIDEO_START = 'UPDATE_TEACHER_VIDEO_START';
export const UPDATE_TEACHER_VIDEO_SUCCESS = 'UPDATE_TEACHER_VIDEO_SUCCESS';
export const UPDATE_TEACHER_VIDEO_FAILURE = 'UPDATE_TEACHER_VIDEO_FAILURE';



// FETCH AND UPDATE HEADLINE AND ABOUT
export const FETCH_TEACHER_PROFILE_START = 'FETCH_TEACHER_PROFILE_START';
export const FETCH_TEACHER_PROFILE_SUCCESS = 'FETCH_TEACHER_PROFILE_SUCCESS';
export const FETCH_TEACHER_PROFILE_FAILURE = 'FETCH_TEACHER_PROFILE_FAILURE';

export const UPDATE_TEACHER_PROFILE_START = 'UPDATE_TEACHER_PROFILE_START';
export const UPDATE_TEACHER_PROFILE_SUCCESS = 'UPDATE_TEACHER_PROFILE_SUCCESS';
export const UPDATE_TEACHER_PROFILE_FAILURE = 'UPDATE_TEACHER_PROFILE_FAILURE';


//DETAIL AND PRICE FETCH AND ABOUT
export const FETCH_TEACHER_DETAILS_START = 'FETCH_TEACHER_DETAILS_START';
export const FETCH_TEACHER_DETAILS_SUCCESS = 'FETCH_TEACHER_DETAILS_SUCCESS';
export const FETCH_TEACHER_DETAILS_FAILURE = 'FETCH_TEACHER_DETAILS_FAILURE';

export const UPDATE_TEACHER_DETAILS_START = 'UPDATE_TEACHER_DETAILS_START';
export const UPDATE_TEACHER_DETAILS_SUCCESS = 'UPDATE_TEACHER_DETAILS_SUCCESS';
export const UPDATE_TEACHER_DETAILS_FAILURE = 'UPDATE_TEACHER_DETAILS_FAILURE';


//Availabilities  UPDATE AND FETHCH
export const FETCH_TEACHER_AVAILABILITY_START = 'FETCH_TEACHER_AVAILABILITY_START';
export const FETCH_TEACHER_AVAILABILITY_SUCCESS = 'FETCH_TEACHER_AVAILABILITY_SUCCESS';
export const FETCH_TEACHER_AVAILABILITY_FAILURE = 'FETCH_TEACHER_AVAILABILITY_FAILURE';

export const UPDATE_TEACHER_AVAILABILITY_START = 'UPDATE_TEACHER_AVAILABILITY_START';
export const UPDATE_TEACHER_AVAILABILITY_SUCCESS = 'UPDATE_TEACHER_AVAILABILITY_SUCCESS';
export const UPDATE_TEACHER_AVAILABILITY_FAILURE = 'UPDATE_TEACHER_AVAILABILITY_FAILURE';
export const SHOW_SUCCESS_NOTIFICATION = 'SHOW_SUCCESS_NOTIFICATION'; // Constant for showing success notification
export const RESET_SUCCESS_NOTIFICATION = 'SHOW_SUCCESS_NOTIFICATION'
//Documents
export const FETCH_TEACHER_DOCUMENTS_START = 'FETCH_TEACHER_DOCUMENTS_START';
export const FETCH_TEACHER_DOCUMENTS_SUCCESS = 'FETCH_TEACHER_DOCUMENTS_SUCCESS';
export const FETCH_TEACHER_DOCUMENTS_FAILURE = 'FETCH_TEACHER_DOCUMENTS_FAILURE';

export const CREATE_TEACHER_DOCUMENT_START = 'CREATE_TEACHER_DOCUMENT_START';
export const CREATE_TEACHER_DOCUMENT_SUCCESS = 'CREATE_TEACHER_DOCUMENT_SUCCESS';
export const CREATE_TEACHER_DOCUMENT_FAILURE = 'CREATE_TEACHER_DOCUMENT_FAILURE';

export const UPDATE_TEACHER_DOCUMENT_START = 'UPDATE_TEACHER_DOCUMENT_START';
export const UPDATE_TEACHER_DOCUMENT_SUCCESS = 'UPDATE_TEACHER_DOCUMENT_SUCCESS';
export const UPDATE_TEACHER_DOCUMENT_FAILURE = 'UPDATE_TEACHER_DOCUMENT_FAILURE';


export const FETCH_PRICE_START = 'FETCH_PRICE_START';
export const FETCH_PRICE_SUCCESS = 'FETCH_PRICE_SUCCESS';
export const FETCH_PRICE_FAILURE = 'FETCH_PRICE_FAILURE';

export const UPDATE_PRICE_START = 'UPDATE_PRICE_START';
export const UPDATE_PRICE_SUCCESS = 'UPDATE_PRICE_SUCCESS';
export const UPDATE_PRICE_FAILURE = 'UPDATE_PRICE_FAILURE';


export const UPDATE_TEACHER_DOCUMENTS_START = 'UPDATE_TEACHER_DOCUMENTS_START';
export const UPDATE_TEACHER_DOCUMENTS_SUCCESS = 'UPDATE_TEACHER_DOCUMENTS_SUCCESS';
export const UPDATE_TEACHER_DOCUMENTS_FAILURE = 'UPDATE_TEACHER_DOCUMENTS_FAILURE';

export const FETCH_COMPLETION_STATUS_SUCCESS= 'FETCH_COMPLETION_STATUS_SUCCESS'
export const FETCH_COMPLETION_STATUS_FAILURE= 'FETCH_COMPLETION_STATUS_FAILURE'

export const SEND_REVIEW_EMAIL_START = 'SEND_REVIEW_EMAIL_START';
export const SEND_REVIEW_EMAIL_SUCCESS = 'SEND_REVIEW_EMAIL_SUCCESS';
export const SEND_REVIEW_EMAIL_FAILURE = 'SEND_REVIEW_EMAIL_FAILURE';


export const RESCHEDULE_LESSON_REQUEST = 'RESCHEDULE_LESSON_REQUEST';
export const RESCHEDULE_LESSON_SUCCESS = 'RESCHEDULE_LESSON_SUCCESS';
export const RESCHEDULE_LESSON_FAILED = 'RESCHEDULE_LESSON_FAILED';


//Canceling the photo
export const CANCEL_LESSON_REQUEST = 'CANCEL_LESSON_REQUEST';
export const CANCEL_LESSON_SUCCESS = 'CANCEL_LESSON_SUCCESS';
export const CANCEL_LESSON_FAILED = 'CANCEL_LESSON_FAILED';

export const GOOGLE_AUTH_SUCCESS = 'GOOGLE_AUTH_SUCCESS';
export const GOOGLE_AUTH_FAIL = 'GOOGLE_AUTH_FAIL';


export const RESET_CHAT = 'RESET_CHAT';
export const UPDATE_CHAT_GROUPS = 'UPDATE_CHAT_GROUPS';

// ActionTypes.js
export const UPDATE_REVIEW_STATUS_REQUEST = 'UPDATE_REVIEW_STATUS_REQUEST';
export const UPDATE_REVIEW_STATUS_SUCCESS = 'UPDATE_REVIEW_STATUS_SUCCESS';
export const UPDATE_REVIEW_STATUS_FAILURE = 'UPDATE_REVIEW_STATUS_FAILURE';


//Fetching history payment please
export const FETCH_STUDENT_BALANCE_TRANSACTIONS_REQUEST = 'FETCH_STUDENT_BALANCE_TRANSACTIONS_REQUEST';
export const FETCH_STUDENT_BALANCE_TRANSACTIONS_SUCCESS = 'FETCH_STUDENT_BALANCE_TRANSACTIONS_SUCCESS';
export const FETCH_STUDENT_BALANCE_TRANSACTIONS_FAILURE = 'FETCH_STUDENT_BALANCE_TRANSACTIONS_FAILURE';

// Wallet Teacher
export const FETCH_WALLET_REQUEST = 'FETCH_WALLET_REQUEST';
export const FETCH_WALLET_SUCCESS = 'FETCH_WALLET_SUCCESS';
export const FETCH_WALLET_FAILURE = 'FETCH_WALLET_FAILURE';

//Verification code for Payout
export const SEND_VERIFICATION_CODE_REQUEST = 'SEND_VERIFICATION_CODE_REQUEST';
export const SEND_VERIFICATION_CODE_SUCCESS = 'SEND_VERIFICATION_CODE_SUCCESS';
export const SEND_VERIFICATION_CODE_FAILURE = 'SEND_VERIFICATION_CODE_FAILURE';

export const VERIFY_CODE_REQUEST = 'VERIFY_CODE_REQUEST';
export const VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS';
export const VERIFY_CODE_FAILURE = 'VERIFY_CODE_FAILURE';

//send request to 
export const REQUEST_PAYOUT_REQUEST = 'REQUEST_PAYOUT_REQUEST';
export const REQUEST_PAYOUT_SUCCESS = 'REQUEST_PAYOUT_SUCCESS';
export const REQUEST_PAYOUT_FAILURE = 'REQUEST_PAYOUT_FAILURE';

//Fetch the history of payments
export const FETCH_PAYOUT_REQUESTS_REQUEST = 'FETCH_PAYOUT_REQUESTS_REQUEST';
export const FETCH_PAYOUT_REQUESTS_SUCCESS = 'FETCH_PAYOUT_REQUESTS_SUCCESS';
export const FETCH_PAYOUT_REQUESTS_FAILURE = 'FETCH_PAYOUT_REQUESTS_FAILURE';

export const SET_UNREAD_CHATS_COUNT = "SET_UNREAD_CHATS_COUNT";


//Cancel and pay feature
export const CANCEL_AND_PAY_LESSON_REQUEST = 'CANCEL_AND_PAY_LESSON_REQUEST';
export const CANCEL_AND_PAY_LESSON_SUCCESS = 'CANCEL_AND_PAY_LESSON_SUCCESS';
export const CANCEL_AND_PAY_LESSON_FAILURE = 'CANCEL_AND_PAY_LESSON_FAILURE';

// Confirm lesson manually by teacher
export const CONFIRM_LESSON_REQUEST = 'CONFIRM_LESSON_REQUEST';
export const CONFIRM_LESSON_SUCCESS = 'CONFIRM_LESSON_SUCCESS';
export const CONFIRM_LESSON_FAILED = 'CONFIRM_LESSON_FAILED';

// Signup Support
export const SUPPORT_SIGNUP_REQUEST = 'SUPPORT_SIGNUP_REQUEST';
export const SUPPORT_SIGNUP_SUCCESS = 'SUPPORT_SIGNUP_SUCCESS';
export const SUPPORT_SIGNUP_FAILURE = 'SUPPORT_SIGNUP_FAILURE';

// Get support Data
export const SUPPORT_DASHBOARD_REQUEST = 'SUPPORT_DASHBOARD_REQUEST';
export const SUPPORT_DASHBOARD_SUCCESS = 'SUPPORT_DASHBOARD_SUCCESS';
export const SUPPORT_DASHBOARD_FAILURE = 'SUPPORT_DASHBOARD_FAILURE';

//Fetch Teachers info
export const FETCH_TEACHERS_REQUEST = 'FETCH_TEACHERS_REQUEST';
export const FETCH_TEACHERS_SUCCESS = 'FETCH_TEACHERS_SUCCESS';
export const FETCH_TEACHERS_FAILURE = 'FETCH_TEACHERS_FAILURE';

//Fetch detail infro of teachers
export const FETCH_TEACHER_DETAILS_REQUEST = 'FETCH_TEACHER_DETAILS_REQUEST';
export const FETCH_TEACHER_DETAILS_SUCCESSS = 'FETCH_TEACHER_DETAILS_SUCCESS';
export const FETCH_TEACHER_DETAILS_FAILUREE = 'FETCH_TEACHER_DETAILS_FAILURE';

//Accept the teacher to be shown in 
export const UPDATE_TEACHER_PROFILE_STATUS_REQUEST = 'UPDATE_TEACHER_PROFILE_STATUS_REQUEST';
export const UPDATE_TEACHER_PROFILE_STATUS_SUCCESS = 'UPDATE_TEACHER_PROFILE_STATUS_SUCCESS';
export const UPDATE_TEACHER_PROFILE_STATUS_FAILURE = 'UPDATE_TEACHER_PROFILE_STATUS_FAILURE';


//learning goal students
export const SET_LEARNING_GOAL = 'SET_LEARNING_GOAL';
export const SET_AVAILABILITYY = 'SET_AVAILABILITYY';
export const SET_LANGUAGE_INFO = 'SET_LANGUAGE_INFO';
export const SET_MARKETING_INFO = 'SET_MARKETING_INFO';
export const SET_TEACHER_ID = 'SET_TEACHER_ID';
