import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import  profileReducer  from "./profileReducer";
import ChatReducer from "./chatReducer";
import  availabilitiesReducer  from "./calendarReducer";
import availabilityReducer from './availabilityReducer';
import lessonReducer from './lessonReducer'
import packagesReducer from "./packagesReducer";
import fetchLessonsReducer from "./fetchLessonsReducer";
import classroomReducer from "./classroomReducer"
import reviewsReducer from "./reviewsReducer"
import teacherReducer from "./teacherApplicationReducer";
import transactionReducer from "./transactionReducer";
import walletReducer from "./walletReducer"
import { teachersFetchReducer } from "./teachersFetchReducer";
import { studentGoalReducer } from "./studentGoalReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  chat: ChatReducer,
  availabilities: availabilitiesReducer,
  availability: availabilityReducer,
  lesson: lessonReducer,
  package:packagesReducer,
  lessons: fetchLessonsReducer,
  classroom: classroomReducer,
  reviews: reviewsReducer,
  teacherApplication:teacherReducer,
  transactions: transactionReducer,
  wallet: walletReducer,
  teachersFetch: teachersFetchReducer,
  studentGoal: studentGoalReducer,
});

export default rootReducer;
