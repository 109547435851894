import {
    AVAILABILITY_REQUEST,
    UPDATE_AVAILABILITIES_SUCCESS,
    UPDATE_AVAILABILITIES_FAILURE,
    FETCH_TEACHER_AVAILABILITY_REQUEST,
    FETCH_TEACHER_AVAILABILITY_SUCCESS,
    FETCH_TEACHER_AVAILABILITY_FAILURE,
    RESET_UPDATE_SUCCESS,
  } from '../actions/types';
  
  const initialState = {
    isLoading: false,
    error: null,
    updateSuccess: false  // New state for update success
  };
  
  const availabilitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case AVAILABILITY_REQUEST:
        case FETCH_TEACHER_AVAILABILITY_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case UPDATE_AVAILABILITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: null,
                updateSuccess: true  // Set updateSuccess to true here
            };
        case FETCH_TEACHER_AVAILABILITY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: null,
                availabilities: action.payload  // store the fetched availabilities
            };
        case RESET_UPDATE_SUCCESS:
    return {
        ...state,
        updateSuccess: false
    };
        case UPDATE_AVAILABILITIES_FAILURE:
        case FETCH_TEACHER_AVAILABILITY_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default availabilitiesReducer;

