import {
  UPLOAD_TEACHER_PHOTO_START,
  UPLOAD_TEACHER_PHOTO_SUCCESS,
  UPLOAD_TEACHER_PHOTO_FAILURE,
  UPDATE_TEACHER_VIDEO_START,
  UPDATE_TEACHER_VIDEO_SUCCESS,
  UPDATE_TEACHER_VIDEO_FAILURE,
  FETCH_TEACHER_PHOTO_START,
  FETCH_TEACHER_PHOTO_SUCCESS,
  FETCH_TEACHER_PHOTO_FAILURE,
  FETCH_TEACHER_PROFILE_START,
  FETCH_TEACHER_PROFILE_SUCCESS,
  FETCH_TEACHER_PROFILE_FAILURE,
  UPDATE_TEACHER_PROFILE_START,
  UPDATE_TEACHER_PROFILE_SUCCESS,
  UPDATE_TEACHER_PROFILE_FAILURE,
  FETCH_TEACHER_VIDEO_START,
  FETCH_TEACHER_VIDEO_SUCCESS,
  FETCH_TEACHER_VIDEO_FAILURE,
  FETCH_TEACHER_DETAILS_START,
  FETCH_TEACHER_DETAILS_SUCCESS,
  FETCH_TEACHER_DETAILS_FAILURE,
  UPDATE_TEACHER_DETAILS_START,
  UPDATE_TEACHER_DETAILS_SUCCESS,
  UPDATE_TEACHER_DETAILS_FAILURE,
  FETCH_TEACHER_AVAILABILITY_START,
  FETCH_TEACHER_AVAILABILITY_SUCCESS,
  FETCH_TEACHER_AVAILABILITY_FAILURE,
  UPDATE_TEACHER_AVAILABILITY_START,
  UPDATE_TEACHER_AVAILABILITY_SUCCESS,
  UPDATE_TEACHER_AVAILABILITY_FAILURE,
  FETCH_TEACHER_DOCUMENTS_START,
  FETCH_TEACHER_DOCUMENTS_SUCCESS,
  FETCH_TEACHER_DOCUMENTS_FAILURE,
  CREATE_TEACHER_DOCUMENT_START,
  CREATE_TEACHER_DOCUMENT_SUCCESS,
  CREATE_TEACHER_DOCUMENT_FAILURE,
  UPDATE_TEACHER_DOCUMENT_START,
  UPDATE_TEACHER_DOCUMENT_SUCCESS,
  UPDATE_TEACHER_DOCUMENT_FAILURE,
  SHOW_SUCCESS_NOTIFICATION,
  RESET_SUCCESS_NOTIFICATION,
  FETCH_COMPLETION_STATUS_SUCCESS,
  FETCH_COMPLETION_STATUS_FAILURE,
  SEND_REVIEW_EMAIL_START,
  SEND_REVIEW_EMAIL_SUCCESS,
  SEND_REVIEW_EMAIL_FAILURE,
  FETCH_PRICE_START,
  FETCH_PRICE_SUCCESS,
  FETCH_PRICE_FAILURE,
  UPDATE_PRICE_START,
  UPDATE_PRICE_SUCCESS,
  UPDATE_PRICE_FAILURE,
  UPDATE_REVIEW_STATUS_REQUEST,
  UPDATE_REVIEW_STATUS_SUCCESS,
  UPDATE_REVIEW_STATUS_FAILURE
} from '../actions/types';

const initialTeacherState = {
  currentStep: 1, // Track the current step in the form
  photoUrl: null, // URL of the uploaded photo
  uploadingPhoto: false, // Flag for photo upload status
  photoUploadError: null, // Error for photo upload
  introductionVideo: null, // URL of the teacher's introduction video
  updatingVideo: false, // Flag for video update status
  videoUpdateError: null, // Error for video update
  fetchingPhoto: false, // Flag for fetching photo status
  fetchPhotoError: null, // Error for fetching photo
  fetchingVideo: false, // Flag for fetching video status
  fetchVideoError: null, // Error for fetching video
  profile: null, // Teacher's profile details
  fetchingDetails: false, // Flag for fetching profile details status
  updatingDetails: false, // Flag for updating profile details status
  detailsError: null, // Error for profile details operations
  fetchingAvailability: false,
  updatingAvailability: false,
  availabilityError: null,
  availability: [],
  documents: [],
  fetchingDocuments: false,
  creatingDocument: false,
  updatingDocument: false,
  documentsError: null,
  showSuccessNotification: false, // This should be false initially

  loading: false,
  application_review_complete: false,

  price: null,
  fetchingPrice: false,
  updatingPrice: false,
  error: null,

  //Status
  auth_complete: false,
  photo_complete: false,
  video_upload_complete: false,
  headline_complete: false,
  competency_complete: false,
  availability_complete: false,
  verification_complete: false,
  completionError: null,

    // New email sending statuses
    reviewEmailLoading: false,
    reviewEmailSuccess: false,
    reviewEmailError: null,
};



const teacherReducer = (state = initialTeacherState, action) => {
  switch (action.type) {
    case UPLOAD_TEACHER_PHOTO_START:
      return { ...state, uploadingPhoto: true, photoUploadError: null };

    case UPLOAD_TEACHER_PHOTO_SUCCESS:
      return { ...state, uploadingPhoto: false, photoUrl: action.payload, photoUploadError: null };

    case UPLOAD_TEACHER_PHOTO_FAILURE:
      return { ...state, uploadingPhoto: false, photoUploadError: action.payload };

    case UPDATE_TEACHER_VIDEO_START:
      return { ...state, updatingVideo: true, videoUpdateError: null };

    case UPDATE_TEACHER_VIDEO_SUCCESS:
      return { ...state, updatingVideo: false, introductionVideo: action.payload, videoUpdateError: null };

    case UPDATE_TEACHER_VIDEO_FAILURE:
      return { ...state, updatingVideo: false, videoUpdateError: action.payload };

      case FETCH_TEACHER_VIDEO_START:
        return { ...state, fetchingVideo: true, fetchVideoError: null };

    case FETCH_TEACHER_VIDEO_SUCCESS:
        return { ...state, fetchingVideo: false, introductionVideo: action.payload, fetchVideoError: null };

    case FETCH_TEACHER_VIDEO_FAILURE:
        return { ...state, fetchingVideo: false, fetchVideoError: action.payload };

      case FETCH_TEACHER_PHOTO_START:
      return { ...state, fetchingPhoto: true, fetchPhotoError: null }; // Added this line

      case FETCH_TEACHER_PHOTO_SUCCESS:
        return { ...state, fetchingPhoto: false, photoUrl: action.payload, fetchPhotoError: null };    

    case FETCH_TEACHER_PHOTO_FAILURE:
      return { ...state, fetchingPhoto: false, fetchPhotoError: action.payload };

      case FETCH_TEACHER_PROFILE_START:
        return { ...state, fetchingProfile: true, profileError: null };
      case FETCH_TEACHER_PROFILE_SUCCESS:
        return { ...state, fetchingProfile: false, profile: action.payload };
      case FETCH_TEACHER_PROFILE_FAILURE:
        return { ...state, fetchingProfile: false, profileError: action.payload };
      case UPDATE_TEACHER_PROFILE_START:
        return { ...state, updatingProfile: true, profileError: null };
      case UPDATE_TEACHER_PROFILE_SUCCESS:
        return { ...state, updatingProfile: false, profile: action.payload };
      case UPDATE_TEACHER_PROFILE_FAILURE:
        return { ...state, updatingProfile: false, profileError: action.payload };
        case FETCH_TEACHER_DETAILS_START:
          return { ...state, fetchingDetails: true, detailsError: null };
    
          case FETCH_TEACHER_DETAILS_SUCCESS:
            return { ...state, fetchingDetails: false, profile: action.payload };
          case FETCH_TEACHER_DETAILS_FAILURE:
            return { ...state, fetchingDetails: false, detailsError: action.payload };
          case UPDATE_TEACHER_DETAILS_START:
            return { ...state, updatingDetails: true, detailsError: null };
          case UPDATE_TEACHER_DETAILS_SUCCESS:
            return { ...state, updatingDetails: false, profile: action.payload };
          case UPDATE_TEACHER_DETAILS_FAILURE:
            return { ...state, updatingDetails: false, detailsError: action.payload };
            case FETCH_TEACHER_AVAILABILITY_START:
              return { ...state, fetchingAvailability: true, availabilityError: null };
        
            case FETCH_TEACHER_AVAILABILITY_SUCCESS:
              return { ...state, fetchingAvailability: false, availability: action.payload };
        
            case FETCH_TEACHER_AVAILABILITY_FAILURE:
              return { ...state, fetchingAvailability: false, availabilityError: action.payload };
        
            case UPDATE_TEACHER_AVAILABILITY_START:
              return { ...state, updatingAvailability: true, availabilityError: null };
        
            case UPDATE_TEACHER_AVAILABILITY_SUCCESS:
              // Updating availability might also involve updating the profile if they are linked
              return { ...state, updatingAvailability: false, availability: action.payload, profile: { ...state.profile, availability: action.payload } };
        
            case UPDATE_TEACHER_AVAILABILITY_FAILURE:
              return { ...state, updatingAvailability: false, availabilityError: action.payload };
              case SHOW_SUCCESS_NOTIFICATION:
                return { ...state, showSuccessNotification: true };
          
              // You may want to add another case to reset this flag
              case RESET_SUCCESS_NOTIFICATION:
                return { ...state, showSuccessNotification: false };
                case FETCH_TEACHER_DOCUMENTS_START:
                  return { ...state, fetchingDocuments: true, documentsError: null };
                case FETCH_TEACHER_DOCUMENTS_SUCCESS:
                  return { ...state, fetchingDocuments: false, documents: action.payload };
                case FETCH_TEACHER_DOCUMENTS_FAILURE:
                  return { ...state, fetchingDocuments: false, documentsError: action.payload };
            
                case CREATE_TEACHER_DOCUMENT_START:
                  return { ...state, creatingDocument: true, documentsError: null };
                case CREATE_TEACHER_DOCUMENT_SUCCESS:
                  return { ...state, creatingDocument: false, documents: [...state.documents, action.payload] };
                case CREATE_TEACHER_DOCUMENT_FAILURE:
                  return { ...state, creatingDocument: false, documentsError: action.payload };
            
                case UPDATE_TEACHER_DOCUMENT_START:
                  return { ...state, updatingDocument: true, documentsError: null };
                case UPDATE_TEACHER_DOCUMENT_SUCCESS:
                  // Assuming each document has a unique identifier (e.g., id) included in the payload.
                  const updatedDocuments = state.documents.map(doc => 
                    doc.id === action.payload.id ? action.payload : doc
                  );
                  return { ...state, updatingDocument: false, documents: updatedDocuments };
                case UPDATE_TEACHER_DOCUMENT_FAILURE:
                  return { ...state, updatingDocument: false, documentsError: action.payload };
                case FETCH_COMPLETION_STATUS_SUCCESS:
                  return {
                    ...state,
                    auth_complete: action.payload.auth_complete,
                    photo_complete: action.payload.photo_complete,
                    video_upload_complete: action.payload.video_upload_complete,
                    headline_complete: action.payload.headline_complete,
                    competency_complete: action.payload.competency_complete,
                    availability_complete: action.payload.availability_complete,
                    verification_complete: action.payload.verification_complete,
                    application_review_complete: action.payload.application_review_complete,
                    completionError: null,
                  };
                case FETCH_COMPLETION_STATUS_FAILURE:
                  return {
                    ...state,
                    completionError: action.payload,
                  };
                  case 'COMPLETE_STEP':
                    const newStepCompletion = { ...state.stepCompletion };
                    newStepCompletion[action.payload] = true;
                    return {
                        ...state,
                        stepCompletion: newStepCompletion
                    };
                    case SEND_REVIEW_EMAIL_START:
      return {
        ...state,
        reviewEmailLoading: true,
        reviewEmailError: null,
      };
    case SEND_REVIEW_EMAIL_SUCCESS:
      return {
        ...state,
        reviewEmailLoading: false,
        reviewEmailSuccess: true,
      };
    case SEND_REVIEW_EMAIL_FAILURE:
      return {
        ...state,
        reviewEmailLoading: false,
        reviewEmailError: action.payload,
      };

      case FETCH_PRICE_START:
        return {
            ...state,
            fetchingPrice: true,
            error: null
        };
    case FETCH_PRICE_SUCCESS:
        return {
            ...state,
            price: action.payload.price_per_hour,  // Assuming payload contains price_per_hour directly
            fetchingPrice: false,
            error: null
        };
    case FETCH_PRICE_FAILURE:
        return {
            ...state,
            fetchingPrice: false,
            error: action.payload
        };
    case UPDATE_PRICE_START:
        return {
            ...state,
            updatingPrice: true,
            error: null
        };
    case UPDATE_PRICE_SUCCESS:
        return {
            ...state,
            price: action.payload.price_per_hour,  // Update price if necessary
            updatingPrice: false,
            error: null
        };
    case UPDATE_PRICE_FAILURE:
        return {
            ...state,
            updatingPrice: false,
            error: action.payload
        };

        case UPDATE_REVIEW_STATUS_REQUEST:
          return {
            ...state,
            loading: true,
            error: null // Optionally clear any previous errors on new request
          };
        case UPDATE_REVIEW_STATUS_SUCCESS:
          return {
            ...state,
            loading: false,
            application_review_complete: action.payload.application_review_complete, // Update the status from payload
            error: null
          };
        case UPDATE_REVIEW_STATUS_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload
          };

    // Include cases for additional actions here

    default:
      return state;
      
  }
};

export default teacherReducer;
