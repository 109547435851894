import * as types from "../actions/types.js";

// reducers/chatReducer.js
const initialState = {
  chatGroups: [],
  messages: [],
  unreadChats: [],
  unreadChatsCount: 0,
  unreadCount: 0,
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHAT_GROUPS:
      return {
        ...state,
        chatGroups: action.payload.initialChatGroups,
      };

    case types.SELECTED_CHAT_GROUP:
      return {
        ...state,
        selectedChatGroup: action.payload.chatGroup,
      };

      case types.MESSAGES:
        return {
          ...state,
          messages: action.payload.messages,
        };
  

        case types.NEW_MESSAGE:
          return {
            ...state,
            messages: action.payload.messages,
          };        

          case types.UNREAD_CHATS:
            return {
              ...state,
              unreadChats: action.payload.newUnread,
            };

    case types.NEW_CHAT_GROUP:
      return {
        ...state,
        chatGroups: [action.payload.newChatGroup, ...(Array.isArray(action.payload.oldGroups) ? action.payload.oldGroups : [])],
      };

    case types.NEW_SINGLE_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload.message],
      };

    case types.UPDATE_CHAT_GROUPS:
      return {
        ...state,
        chatGroups: action.payload,
      };

    case types.UNREAD_CHATS_COUNT:
      return {
        ...state,
        unreadChatsCount: action.payload,
      };

      case types.SET_UNREAD_COUNT:
        return {
          ...state,
          unreadCount: action.payload,
        };      
      

    case types.RESET_CHAT:
      return initialState;
      
      case types.ADD_PREVIOUS_MESSAGES:
        return {
          ...state,
          messages: action.payload.messages,
        };

    default:
      return state;
  }
};

export default chatReducer;


