import {
  SET_SESSION_INFO_START,
  SET_SESSION_INFO_SUCCESS,
  SET_SESSION_INFO_FAIL,
  GET_SESSION_INFO_START,
  GET_SESSION_INFO_SUCCESS,
  GET_SESSION_INFO_FAIL,
} from "../actions/types";


const initialState = {
  loading: false,
  error: null,
  channelId: null,
  channelName: null,
  teacherToken: null,
  studentToken: null,
  teacherUid: null,
  studentUid: null,
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION_INFO_START:
    case GET_SESSION_INFO_START:
      return { ...state, loading: true, error: null };
    case SET_SESSION_INFO_SUCCESS:
    case GET_SESSION_INFO_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case SET_SESSION_INFO_FAIL:
    case GET_SESSION_INFO_FAIL:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export default reducer;

