// bookingReducer.js
import { BOOK_LESSON_SUCCESS, 
              BOOK_LESSON_FAILED,
              RESCHEDULE_LESSON_REQUEST,
              RESCHEDULE_LESSON_SUCCESS,
              RESCHEDULE_LESSON_FAILED,
              CANCEL_LESSON_REQUEST,
              CANCEL_LESSON_SUCCESS,
              CANCEL_LESSON_FAILED,
              CANCEL_AND_PAY_LESSON_REQUEST,
              CANCEL_AND_PAY_LESSON_SUCCESS,
              CANCEL_AND_PAY_LESSON_FAILURE,
              CONFIRM_LESSON_REQUEST,
              CONFIRM_LESSON_SUCCESS,
              CONFIRM_LESSON_FAILED,
            } from "../actions/types";

            const initialState = {
              lessonBooking: null,
              bookingError: null,
              selectedTime: null,
              selectedSlot: null,
              rescheduling: false,
              rescheduleError: null,
              lessons: [],
              canceling: false,
              cancelError: null,
              cancelAndPaying: false,
              cancelAndPayError: null,
              confirming: false,
              confirmError: null,
            };
            
            const lesson = (state = initialState, action) => {
              const { type, payload } = action;
            
              switch (type) {
                case BOOK_LESSON_SUCCESS:
                  return {
                    ...state,
                    lessonBooking: payload,
                    bookingError: null,
                    selectedTime: action.payload.start_time,
                  };
                case BOOK_LESSON_FAILED:
                  return {
                    ...state,
                    lessonBooking: null,
                    bookingError: "Failed to book the lesson",
                  };
                case 'SET_SELECTED_SLOT':
                  return {
                    ...state,
                    selectedSlot: payload,
                  };
                case 'CLEAR_SELECTED_SLOT':
                  return {
                    ...state,
                    selectedSlot: null,
                  };
                  case RESCHEDULE_LESSON_REQUEST:
                    return {
                      ...state,
                      rescheduling: true,
                      rescheduleError: null,
                    };
                  case RESCHEDULE_LESSON_SUCCESS:
                    return {
                      ...state,
                      lessonBooking: payload,
                      rescheduling: false,
                      rescheduleError: null,
                    };
                  case RESCHEDULE_LESSON_FAILED:
                    return {
                      ...state,
                      rescheduling: false,
                      rescheduleError: payload,
                    };
                    case CANCEL_LESSON_REQUEST:
                      return { loading: true };
                  case CANCEL_LESSON_SUCCESS:
                      return { loading: false, success: true, lesson: action.payload };
                  case CANCEL_LESSON_FAILED:
                      return { loading: false, error: action.payload };
                case CANCEL_AND_PAY_LESSON_REQUEST:
                  return {
                    ...state,
                    cancelAndPaying: true,
                    cancelAndPayError: null,
                  };
                case CANCEL_AND_PAY_LESSON_SUCCESS:
                  return {
                    ...state,
                    cancelAndPaying: false,
                    lessonBooking: state.lessonBooking.filter(lesson => lesson.id !== payload.id), // or however you want to update the state
                  };
                case CANCEL_AND_PAY_LESSON_FAILURE:
                  return {
                    ...state,
                    cancelAndPaying: false,
                    cancelAndPayError: payload,
                  };
                  case CONFIRM_LESSON_SUCCESS:
                    return {
                      ...state,
                      confirming: false,
                      lessonBooking: payload,
                      confirmError: null,
                    };
                  case CONFIRM_LESSON_FAILED:
                    return {
                      ...state,
                      confirming: false,
                      confirmError: payload,
                    };
                default:
                  return state;
              }
            };
            
            export default lesson;