import { FETCH_LESSONS } from "../actions/types";



const initialState = {
    lessons: [],
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case FETCH_LESSONS:
        return {
          ...state,
          lessons: action.payload,
        };
      default:
        return state;
    }
  }