import "@/styles/globals.css";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "../redux/store";
import { Provider } from "react-redux";
import AppLayout from "../components/Layouts/appLayout";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { appWithTranslation } from 'next-i18next';
import './custom.css';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import Script from 'next/script';
import ErrorBoundary from "../components/ErrorBoundary";
import { GoogleTagManager } from "@next/third-parties/google";
import { SpeedInsights } from '@vercel/speed-insights/next';
import { DefaultSeo } from "next-seo";
import SEO from "next-seo.config";

const App = ({ Component, pageProps }) => {
  const gtmId = "GTM-TT7656HK";
  const skipPersistGate = Component.skipPersistGate || false;

  return (
    <GoogleOAuthProvider clientId="290476428672-c145ua43i10ee67oa7j1ugcv9rovn1u0.apps.googleusercontent.com">
      <Provider store={store}>
        {skipPersistGate ? (
          <AppLayout>
            <Component {...pageProps} />
          </AppLayout>
        ) : (
          <PersistGate loading={null} persistor={persistor}>
            <AppLayout>
              <Component {...pageProps} />
            </AppLayout>
          </PersistGate>
        )}
        <Head>
          <link rel="icon" href="/favicon.ico" />
        </Head>

        {/* External Scripts */}
        <Script src={`https://www.googletagmanager.com/gtm.js?id=${gtmId}`} strategy="lazyOnload" />
        <Script src="https://apis.google.com/js/platform.js" async defer />
        <Script src="https://embed.tawk.to/6601b8951ec1082f04daf80e/1hprbi2jh" strategy="lazyOnload" crossOrigin="*" />
      </Provider>
    </GoogleOAuthProvider>
  );
};

export const getServerSideProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common'], null, ['en', 'fr'])),
    // Assume isAppLoading comes from somewhere or some condition
  },
});

export default appWithTranslation(App);
