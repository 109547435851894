// walletReducer.js
import {
    FETCH_WALLET_REQUEST,
    FETCH_WALLET_SUCCESS,
    FETCH_WALLET_FAILURE,
    SEND_VERIFICATION_CODE_REQUEST,
    SEND_VERIFICATION_CODE_SUCCESS,
    SEND_VERIFICATION_CODE_FAILURE,
    VERIFY_CODE_REQUEST,
    VERIFY_CODE_SUCCESS,
    VERIFY_CODE_FAILURE,
    REQUEST_PAYOUT_REQUEST,
    REQUEST_PAYOUT_SUCCESS,
    REQUEST_PAYOUT_FAILURE,
    FETCH_PAYOUT_REQUESTS_REQUEST,
    FETCH_PAYOUT_REQUESTS_SUCCESS,
    FETCH_PAYOUT_REQUESTS_FAILURE,
} from '../actions/types';


const initialState = {
    loading: false,
    wallet: null,
    error: null,
    codeSent: false,
    codeVerified: false,
    payoutRequested: false,
    payoutRequests: [], // Initialize as an empty array
};

const walletReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WALLET_REQUEST:
        case REQUEST_PAYOUT_REQUEST:
        case SEND_VERIFICATION_CODE_REQUEST:
        case VERIFY_CODE_REQUEST:
        case FETCH_PAYOUT_REQUESTS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_WALLET_SUCCESS:
            return {
                ...state,
                loading: false,
                wallet: action.payload,
            };
        case FETCH_WALLET_FAILURE:
        case SEND_VERIFICATION_CODE_FAILURE:
        case VERIFY_CODE_FAILURE:
        case REQUEST_PAYOUT_FAILURE:
        case FETCH_PAYOUT_REQUESTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SEND_VERIFICATION_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
                codeSent: true,
            };
        case VERIFY_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
                codeVerified: true,
            };
        case REQUEST_PAYOUT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case FETCH_PAYOUT_REQUESTS_SUCCESS:
            return {
                ...state,
                loading: false,
                payoutRequests: action.payload,
            };
        default:
            return state;
    }
};

export default walletReducer;