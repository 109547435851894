import { 
    SET_LEARNING_GOAL, 
    SET_AVAILABILITYY, 
    SET_LANGUAGE_INFO, 
    SET_MARKETING_INFO, 
    SET_SPECIFIC_FIELDS,
    SET_TEACHER_ID
  } from '../actions/types';
  
  const initialState = {
    learningGoal: '',
    otherGoal: '',
    weekdays: [],
    preferredTime: '',
    experienceWithLanguage: '',
    nativeLanguage: '',
    alsoSpeak: '',
    interests: '',
    foundPlatform: '',
    
    // Add dynamic fields here
    destination: '',
    travel_date: '',
    child_age: null,
    school_level: '',
    specific_needs: ''
  };
  
  export const studentGoalReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_LEARNING_GOAL:
        return {
          ...state,
          learningGoal: action.payload.learningGoal,
          otherGoal: action.payload.otherGoal,
        };
      case SET_AVAILABILITYY:
        return {
          ...state,
          weekdays: action.payload.weekdays,
          preferredTime: action.payload.preferredTime,
        };
      case SET_LANGUAGE_INFO:
        return {
          ...state,
          experienceWithLanguage: action.payload.experienceWithLanguage,
          nativeLanguage: action.payload.nativeLanguage,
          alsoSpeak: action.payload.alsoSpeak,
          interests: action.payload.interests,
        };
      case SET_MARKETING_INFO:
        return {
          ...state,
          foundPlatform: action.payload.foundPlatform,
        };
      
      // NEW: Handle dynamically set specific fields based on learning goal
      case SET_SPECIFIC_FIELDS:
        return {
          ...state,
          ...action.payload, // Merge dynamic fields
        };

    // NEW: Handle setting the teacher_id
    case SET_TEACHER_ID:
      return {
        ...state,
        teacher_id: action.payload, // Set teacher_id from the action payload
      };
      
      default:
        return state;
    }
  };
  