import {
    FETCH_TEACHERS_REQUEST,
    FETCH_TEACHERS_SUCCESS,
    FETCH_TEACHERS_FAILURE,
    FETCH_TEACHER_DETAILS_REQUEST,
    FETCH_TEACHER_DETAILS_SUCCESSS,
    FETCH_TEACHER_DETAILS_FAILUREE,
    UPDATE_TEACHER_PROFILE_STATUS_REQUEST,
    UPDATE_TEACHER_PROFILE_STATUS_SUCCESS,
    UPDATE_TEACHER_PROFILE_STATUS_FAILURE
} from '../actions/types';


const initialState = {
    teachers: [],
    loading: false,
    error: null,
    teacherDetails: null,
    loadingDetails: false,
    errorDetails: null,
    updateStatusLoading: false,
    updateStatusError: null,
    updateStatusSuccess: false,
};

export const teachersFetchReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEACHERS_REQUEST:
            return { ...state, loading: true };
        case FETCH_TEACHERS_SUCCESS:
            return { ...state, loading: false, teachers: action.payload };
        case FETCH_TEACHERS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case FETCH_TEACHER_DETAILS_REQUEST:
            return { ...state, loadingDetails: true };
        case FETCH_TEACHER_DETAILS_SUCCESSS:
            return { ...state, loadingDetails: false, teacherDetails: action.payload };
        case FETCH_TEACHER_DETAILS_FAILUREE:
            return { ...state, loadingDetails: false, errorDetails: action.payload };
        case UPDATE_TEACHER_PROFILE_STATUS_REQUEST:
            return { ...state, updateStatusLoading: true, updateStatusSuccess: false };
        case UPDATE_TEACHER_PROFILE_STATUS_SUCCESS:
            return { ...state, updateStatusLoading: false, updateStatusSuccess: true, teacherDetails: action.payload };
        case UPDATE_TEACHER_PROFILE_STATUS_FAILURE:
            return { ...state, updateStatusLoading: false, updateStatusError: action.payload };
        default:
            return state;
    }
};