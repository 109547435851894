import {
    CREATE_REVIEW_BEGIN,
    CREATE_REVIEW_SUCCESS,
    CREATE_REVIEW_FAILURE,
    FETCH_REVIEWS_BEGIN,
    FETCH_REVIEWS_SUCCESS,
    FETCH_REVIEWS_FAILURE,
    UPDATE_REVIEW_BEGIN,
    UPDATE_REVIEW_SUCCESS,
    UPDATE_REVIEW_FAILURE,
    DELETE_REVIEW_BEGIN,
    DELETE_REVIEW_SUCCESS,
    DELETE_REVIEW_FAILURE,
} from '../actions/types';


const initialState = {
    reviews: [],
    loading: false,
    error: null,
};

export default function reviewReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_REVIEW_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case CREATE_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                reviews: [...state.reviews, action.payload.review],
            };
        case CREATE_REVIEW_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                reviews: [],
            };
        case FETCH_REVIEWS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_REVIEWS_SUCCESS:
            return {
                ...state,
                loading: false,
                reviews: action.payload.reviews,
            };
        case FETCH_REVIEWS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                reviews: [],
            };
        case UPDATE_REVIEW_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case UPDATE_REVIEW_SUCCESS:
            // Update the review in the state's review list
            const updatedReviews = state.reviews.map(review =>
                review.id === action.payload.review.id ? action.payload.review : review
            );
            return {
                ...state,
                loading: false,
                reviews: updatedReviews,
            };
        case UPDATE_REVIEW_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        default:
            return state;
    }
}
