// next-seo.config.js
// next-seo.config.js
const SEO = {
    title: 'Learn Arabic language and Moroccan language online',
    description: 'Expand your skills with comprehensive Arabic and Moroccan language courses available online. Join now!.',
    openGraph: {
      type: 'website',
      locale: 'en_IE',
      url: 'https://www.yoursite.com/',
      site_name: 'YourSiteName',
    },
    twitter: {
      handle: '@handle',
      site: '@site',
      cardType: 'summary_large_image',
    },
  };
  
  export default SEO;
  