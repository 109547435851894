import { connect } from "react-redux";
import { useSelector } from "react-redux";
import dynamic from "next/dynamic";
import { useEffect } from "react";
import { useRouter } from "next/router";

const UnAuthLayout = dynamic(
  () => import("./unauthorizedLayout/unAuthLayout.js"),
  {
    loading: () => (
      <div className="flex justify-center items-center h-screen">
        <h1>Loading .....</h1>
      </div>
    ),
  }
);

const AuthLayout = dynamic(() => import("./authorizedLayout/authLayout.js"), {
  loading: () => (
    <div className="flex justify-center items-center h-screen">
      <h1>Loading .....</h1>
    </div>
  ),
});

export const metadata = {
  title: 'Learn Arabic language and Moroccan language online',
  description: '...',
}

function AppLayout({ children }) {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const role = useSelector((state) => state.auth.role);  // Get role from state
  const router = useRouter();

  useEffect(() => {
    // Redirect to login if not authenticated and trying to access restricted pages
    if (!isAuthenticated && (role === 'Student' || role === 'Teacher' || role === 'Support')) {
      router.push("/login");
    }
  }, [router, isAuthenticated, role]);

  return isAuthenticated ? <AuthLayout>{children}</AuthLayout> : <UnAuthLayout>{children}</UnAuthLayout>;
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(AppLayout);